import { API, API_TIKTOK, API_WithSwal, SPOTIFY_API } from '../../utility/API'
import { API_WithSwal as Artist_API_WithSwal } from '@modules/artist/utility/API'
import { _setAPIToken } from '../../utility/Utils'
import _ from "lodash"
import { saveAs } from 'file-saver'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import {_toast} from "@utils"


//***************** Curators Vs Campaigns *******************//

export const _addCuratorVsCampaing = (data, callback, callbackErr) => {
  API.post(`curator/curators_vs_campaigns`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}

//************************************//
export const _storeCuratorVsCampaignPlaylist = (data, callback, callbackErr) => {
  API.put(`curator/curator_vs_campaign/playlist/${data.id}`, data)
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}

//************************************//
export const _submitCuratorVsCampaignPlaylist = (id, status, callback, callbackErr) => {
  API_WithSwal.put(`curator/curator_vs_campaign/playlist/submit/${id}/${status}`)
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}

//************************************//
export const _getCampaignInfo = (id, callback, callbackErr) => {
  API.get(`campaign/${id}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}
//************************************//
export const _getArtistCustomCampaigns = (data, callback, callbackErr) => {
  API.get(`artist/campaigns`, {
    params: data
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      // callbackErr(data?.data?.errors)
      const data = error?.response?.data
      if (data) {
        const { data: responseData } = data
        callbackErr(responseData?.errors)
      } else {
        console.log('Error:', error)
      }
    })
}

export const _getCuratorCustomCampaigns = (data, callback, callbackErr) => {
  API.get(`curator/campaigns`, {
    params: data
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      // callbackErr(data?.data?.errors)
      const data = error?.response?.data
      if (data) {
        const { data: responseData } = data
        callbackErr(responseData?.errors)
      } else {
        console.log('Error:', error)
      }
    })
}
//************************************//
export const _getCampaignInfoforView = (id, callback, callbackErr) => {
  API.get(`campaign/getforview/${id}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}

//************************************//
export const _getCampaignByCuratorVsCampaignId = (id, callback, callbackErr) => {
  API.get(`curator/campaign/${id}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}

//************************************//
export const _getPublishedCampaigns = (data, callback, callbackErr) => {
  API.get(`published/campaigns`, {
    params: data
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}


//***************** Users *******************//

export const _resendVerificationCode = () => {
  API_WithSwal.post(`user/resend-code`)
    .then(function ({ data }) {
    })
    .catch(function ({ data }) {
    })
}

export const _getUserInfo = (id, callback) => {
  API.get(`user/get-info/${id}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}

export const _updateUser = (id, data, callback) => {
  API_WithSwal.post(`user/update-user/${id}`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}

//***************** Payouts *******************//

//************************************//
export const _getPayoutInfo = (id, callback) => {
  API.get(`curator/payouts/${id}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}
//************************************//
export const _payPayout = (data, callback, callbackErr) => {
  const formData = new FormData()
  _.each(data, (x, key) => {
    if (key === 'attachment_link') {
      if (!_.isString(x)) {
        formData.append(key, x[0])
      }
    } else {
      formData.append(key, x)
    }
  })
  API_WithSwal.post(`curator/payout/${data.id}`, formData, {
    headers: { "Content-Type": "multipart/form-data" }
  })
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}

//***************** Invoices *******************//

//************************************//
export const _getInvoiceInfo = (id, callback) => {
  API.get(`artist/invoices/${id}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}
//************************************//
export const _payInvoice = (data, callback, callbackErr) => {
  API_WithSwal.post(`artist/invoice/${data.id}`, data)
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}
//************************************//
export const _login = ({ email, password }, callback, callbackErr) => {
  API.post('user/login', { email, password })
    .then(function (res) {
      callback(res)
    })
    .catch(function (res) {
      callbackErr(res)
    })
}

//************************************//
export const _autoLogin = (dispatch, ability, callback) => {
  const storageUserToken = JSON.parse(localStorage.getItem('AUTH_TOKEN'))
  const sessionUserToken = JSON.parse(sessionStorage.getItem('AUTH_TOKEN'))
  let user = (sessionUserToken || false)
  if (user && user.token && user.user) {
    _setAPIToken(user.token)
    // dispatch({type:"USER_LOGIN", userData:user.user, token:user.token})
    // if (user.user.abilities) {
    //   ability.update(user.user.abilities)
    // }
    API.get('user/info', { email: user.email, token: user.token })
      .then(function (res) {
        localStorage.removeItem('currentPathArtist')
        if (res.data.token) {
          const { data } = res
          sessionStorage.setItem("AUTH_TOKEN", JSON.stringify({ email: user.email, token: data.token, user: data.user }))
          localStorage.setItem("AUTH_TOKEN", JSON.stringify({ email: user.email, token: data.token, user: data.user }))
          _setAPIToken(data.token)
          dispatch({ type: "USER_LOGIN", userData: data.user, token: data.token })
          if (data.user.abilities) {
            ability.update(data.user.abilities)
          }
        }
        callback()
      })
      .catch(function (res) {
        localStorage.removeItem('AUTH_TOKEN')
        sessionStorage.removeItem('AUTH_TOKEN')
        callback()
      })
  } else if (user = (storageUserToken || false)) {
    if (user && user.token && user.email) {
      _setAPIToken(user.token)
      API.get('user/info', { email: user.email, token: user.token })
        .then(function (res) {
          if (res.data.token) {
            const { data } = res
            sessionStorage.setItem("AUTH_TOKEN", JSON.stringify({ email: user.email, token: data.token, user: data.user }))
            _setAPIToken(data.token)
            dispatch({ type: "USER_LOGIN", userData: data.user, token: data.token })
            if (data.user.abilities) {
              ability.update(data.user.abilities)
            }
          }
          callback()
        })
        .catch(function (res) {
          localStorage.removeItem('AUTH_TOKEN')
          sessionStorage.removeItem('AUTH_TOKEN')
          callback()
        })
    }
  } else {
    callback()
  }
}


//***************** stripe checkout *******************//
//create checkout
export const _stripeCreateCheckout = (data, callback, callbackErr) => {
  API.post(`stripe/create/checkout`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}

export const _stripePaymentSuccess = (data, callback, callbackErr) => {
  API.post(`stripe/checkout/check/pay`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}
//***************** Admins *******************//
//************************************//
export const _editAdminInfo = (data, callback, callbackErr) => {
  API_WithSwal.put(`admins/${data.id}`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}
//************************************//
export const _deleteAdmin = (id, callback) => {
  API.delete(`admins/${id}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}
export const _deleteUser = (id, callback) => {
  API.delete(`user/delete/${id}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}

//***************** Scraping Jobs *******************//

export const _addScrapingJob = (data, callback, callbackErr) => {
  API_WithSwal.post(`scrapingjobs`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}
//************************************//
export const _editScrapingJob = (data, callback, callbackErr) => {
  API_WithSwal.put(`scrapingjobs/${data.id}`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}
//************************************//
export const _deleteScrapingJob = (id, callback) => {
  API.delete(`scrapingjobs/${id}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}
//************************************//
export const _startScrapingJob = (id, callback) => {
  API.delete(`scrapingjob/start-scraping/${id}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}

//***************** Notifications *******************//

export const _sendPushNotification = (data, callback, callbackErr) => {
  API_WithSwal.post(`notifications`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}
//************************************//
export const _getAllAdminsWithQ = async (q = '') => {
  const { data } = await API.get('admin/getAllAdminsWithQ', {
    params: {
      limit: 20,
      q
    }
  })
  return _.map(data.municipalities, (v, k) => {
    return { value: v.id, label: v.name }
  })
}

//************************************//
export const _getAllGenresWithQ = async (q = '') => {
  const { data } = await API.get('user/genre/getAllGenresWithQ', {
    params: {
      limit: 40,
      q
    }
  })
  return _.map(data.genres, (v, k) => {
    return { value: v.id, label: v.name }
  })
}

//************************************//
export const _getAllInterestsWithQ = (q = '', type, callback) => {
  API.get('interest/getAllInterestsWithQ', {
    params: {
      limit: 20,
      q,
      type
    }
  }).then(function ({ data }) {
    callback(data)
  })
}
//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
export const _loginTwoFactor = ({ email, code }, callback, callbackErr) => {
  API.post('login-2fa', { email, code })
    .then(function (res) {
      callback(res)
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}
//************************************//
export const _register = (data, callback, callbackErr) => {
  API.post('user/store-user', data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
      callbackErr(data.data)
    })
}
//************************************//
export const _forgetPassword = ({ email }, callback, callbackErr) => {
  API.post('forget-password', { email })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
      callbackErr(data.data)
    })
}
//************************************//
export const _resetPassword = (data, callback, callbackErr) => {
  API.post('user/reset-password', data)
    .then(function (res) {
      callback(res)
    })
    .catch(function ({ data }) {
      callbackErr(data?.data)
    })
}
//reset password for the user
export const _resetPasswordLogin = (data, callback, callbackErr) => {
  API_WithSwal.post('user/reset/password/login', data)
    .then(function (res) {
      callback(res)
    })
    .catch(function ({ data }) {
      callbackErr(data?.data)
    })
}
//reset password for the user
export const _checkResetPasswordLogin = (data, callback, callbackErr) => {
  API.get('check/reset/token', {
    params: data
  })
    .then(function (res) {
      callback(res)
    })
    .catch(function ({ data }) {
      callbackErr(data?.data)
    })
}
//************************************//
export const _changePassword = (data, callback, callbackErr) => {
  API.post('user/account/change-password', { ...data })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
      callbackErr(data.data)
    })
}
//************************************//
export const _logout = () => {
  return dispatch => {
    API.post('user/logout')
      .then(function () {
        window.location.href = '/login'
        // ** Remove user, accessToken & refreshToken from localStorage
        localStorage.removeItem('sessionId')
        localStorage.removeItem('AUTH_TOKEN')
        localStorage.removeItem('userBalance')
        sessionStorage.removeItem('AUTH_TOKEN')
        _setAPIToken('')
        dispatch({ type: 'USER_LOGOUT' })
       
        // location.reload()

      })
      .catch(function ({ data }) {
      })
  }
}
//************************************//
export const _getMyProfile = (dispatch) => {
  API.get('user/account/my-profile')
    .then(function ({ data }) {
      const userToken = JSON.parse(sessionStorage.getItem('AUTH_TOKEN'))
      userToken.user = { ...userToken.user, ...data.basic_info }
      sessionStorage.setItem("AUTH_TOKEN", JSON.stringify(userToken))
      dispatch({ type: 'USER_MY_PROFILE', data })
    })
    .catch(function (res) { })
}
//************************************//
export const _changeBasicInfo = (data, callback, callbackErr) => {
  return dispatch => {
    API.post('user/account/change-basic-info', data)
      .then(function ({ data }) {
        _getMyProfile(dispatch)
      })
      .catch(function ({ data }) {
        callbackErr(data.data)
      })
  }

}
//************************************//
export const _changeGeneralInfo = (data, callback, callbackErr) => {
  return dispatch => {
    API.post('user/account/change-general-info', { ...data })
      .then(function ({ data }) {
        _getMyProfile(dispatch)
      })
      .catch(function ({ data }) {
        callbackErr(data.data)
      })
  }

}
//************************************//
export const _saveSupportEmail = (data, callback, callbackErr) => {
  API.post('support/store', { ...data })
    .then(function ({ data }) {
    })
    .catch(function ({ data }) {
      callbackErr(data.data)
    })

}
//************************************//
export const _getAllGenresWithQForFilter = (q = '', callback) => {
  if (q.length < 3) {
    // If the user has not typed at least 3 letters, return an empty array
    return []
  }
  API.get('user/genre/getAllGenresWithQ', {
    params: {
      limit: 20,
      q
    }
  }).then(function ({ data }) {
    callback(data.genres)
  })
}

//************************************//
export const _getAllTiersWithQForFilter = (q = '', type, callback) => {
  API.get('tier/getAllTiersWithQ', {
    params: {
      limit: 100,
      q,
      type
    }
  }).then(({ data }) => {
    callback(data.tiers)
  })
}

// **********************************************
// ******************FROMS APIS******************
// **********************************************
export const _addForm = (data, callback, callbackErr) => {
  API_WithSwal.post(`user/new_form`, data)
    .then(function (data) {
      callback(data)
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}
//************************************//
export const _getSpotifyAutofillRecordTypesWithQ = async (q = '') => {
  const { data } = await API.get('user/get_spotify_record_types', {
    params: {
      limit: 20,
      q
    }
  })
  console.log(data)
  return _.map(data.record_types, (v, k) => {
    return { value: v.id, label: v.type }
  })

}
//************************************//
export const _getSpotifyAutofillCulturesWithQ = async (q = '') => {
  const { data } = await API.get('user/get_spotify_cultures', {
    params: {
      limit: 20,
      q
    }
  })
  console.log(data)
  return _.map(data.cultures, (v, k) => {
    return { value: v.id, label: v.name }
  })

}
//************************************//
export const _getAppleAutofillMoodsWithQ = async (q = '') => {
  const { data } = await API.get('user/get_apple_moods', {
    params: {
      limit: 20,
      q
    }
  })
  console.log(data)
  return _.map(data.moods, (v, k) => {
    return { value: v.id, label: v.name }
  })

}
//************************************//
export const _getAppleAutofillGenresWithQ = async (q = '') => {
  const { data } = await API.get('user/get_apple_genres', {
    params: {
      limit: 20,
      q
    }
  })
  console.log(data)
  return _.map(data.genres, (v, k) => {
    return { value: v.id, label: v.name }
  })

}
//************************************//
export const _getSpotifyAutofillMoodsWithQ = async (q = '') => {
  const { data } = await API.get('user/get_spotify_moods', {
    params: {
      limit: 20,
      q
    }
  })
  console.log(data)
  return _.map(data.moods, (v, k) => {
    return { value: v.id, label: v.name }
  })

}
//************************************//
export const _getSpotifyAutofillInstrumentsWithQ = async (q = '') => {
  const { data } = await API.get('user/get_spotify_instruments', {
    params: {
      limit: 20,
      q
    }
  })
  console.log(data)
  return _.map(data.instruments, (v, k) => {
    return { value: v.id, label: v.name }
  })

}
//************************************//
export const _getSpotifyAutofillLanguagesWithQ = async (q = '') => {
  const { data } = await API.get('user/get_spotify_languages', {
    params: {
      limit: 20,
      q
    }
  })
  console.log(data)
  return _.map(data.languages, (v, k) => {
    return { value: v.id, label: v.name }
  })

}
//************************************//
export const _getSpotifyAutofillSongStylesWithQ = async (q = '') => {
  const { data } = await API.get('user/get_spotify_song_styles', {
    params: {
      limit: 20,
      q
    }
  })
  console.log(data)
  return _.map(data.song_styles, (v, k) => {
    return { value: v.id, label: v.name }
  })

}
export const _getFormInfo = (id, callback) => {
  API.get(`get_form/${id}`)
    .then(function (data) {
      callback(data)
    })
    .catch(function (data) {
    })
}

export const _getUserInfoSpotify = async (callback) => {
  await API.get(`user/info/spotify`)
    .then(function (data) {
      localStorage.removeItem('currentPathArtist')
      callback(data)
    })
    .catch(function (data) {
    })
}
//***************** Express Playlist View ******************* *//

export const _editExpressPlayList = (data, callback, callbackErr) => {
  API_WithSwal.put(`express/curator/playlist/edit/${data.id}`, data)
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}


export const _expressPlayListIsRenewable = (data, callback, callbackErr) => {
  API.put(`express/curator/playlist/renewable`, data)
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}

export const _getExpressCuratorPlaylistDetails = (id, callback) => {
  API.get(`express/curator/shows/playlist/${id}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}

export const _getPerformanceChartStats = (id, callback) => {
  API.get(`express/curator/campaigns/performance/${id}`)
    .then(function ({ data }) {
      callback(data.playlist)
    })
    .catch(function ({ data }) {
    })
}
export const _approveExpressPlaylistCampaign = (data, callback, callbackErr) => {
  console.log(`approve campaign ${JSON.stringify(data)}`)
  API_WithSwal.post(`express/curator/approve/campaign`, data)
    .then(function () {
      callback()
    }).catch(function (data) {
      callbackErr(data?.data)
    })
}
/******************** Campaigns *******************/
//get campaign reasons
export const _getAllReasonsWithQ = async (q = '') => {
  const { data } = await API.get('express/curator/campaign/reasons?', {
    params: {
      limit: 20,
      q
    }
  })
  return _.map(data.reasons, (v, k) => {
    return { value: v.id, label: v.reason }
  })

}
//campaign decline
export const _declineExpressCampaign = (data, callback, callbackErr) => {
  API_WithSwal.post(`express/curator/decline/campaign`, data)
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}
export const _getExpressCampaignInfo = (id, callback) => {
  API.get(`/express/artist/show/campaign/${id}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}
export const _storeCuratorExpressPlaylist = (data, callback, callbackErr) => {
  API_WithSwal.post(`express/curator/playlist/create`, data)
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}
//************************************//
export const _getExpressPlaylistCurator = (data, callback, callbackErr) => {
  API.get(`express/curator/playlists`, {
    params: data
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      // callbackErr(data?.data?.errors)
      const data = error?.response?.data
      if (data) {
        const { data: responseData } = data
        callbackErr(responseData?.errors)
      } else {
        console.log('Error:', error)
      }
    })
}
export const _getExpressPlaylistCampaigns = (data, callback, callbackErr) => {
  API.get(`express/curator/shows/campaigns/${data.id}`, {
    params: data
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      // callbackErr(data?.data?.errors)
      const data = error?.response?.data
      if (data) {
        const { data: responseData } = data
        callbackErr(responseData?.errors)
      } else {
        console.log('Error:', error)
      }
    })
}
/******************** spotify  spotify/user/playlists? *********************/
export const _getCurrentCuratorSpotifyPlaylists = (user, callback, callbackErr) => {
  API.get(`spotify/user/playlists?user=${user}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}

export const _getLoginSpotifyUrl = (reconnect, callback) => {
  API.get(`spotify/login`, {
    params: {
      reconnect
    }
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}
//callback  spotify
export const _getSpotifyCallBack = (code, reconnect, callback, callbackErr) => {
  API.get(`spotify/callback?code=${code}`, {
    params: { reconnect }
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}


/***************   Tiers and Durations and Genres  **************/
export const _getAllExpressPlaylistsTiers = (callback) => {
  API.get('express/curator/all-tiers')
    .then(function ({ data }) {
      console.log(`durations ${data.tiers}`)
      callback(data.tiers)
    })
    .catch(function ({ data }) {
    })
}
export const _getAllExpressPlaylistTierThreshold = async (q = '') => {
  const { data } = await API.get('express/tier/playlist/threshold', {
    params: {
      limit: 50,
      q: q.q,
      followers: q.followers
    }
  })
  return _.map(data.tiers, (v, k) => {
    return { value: v.id, label: `${v.tier_name}, Likes: ${v.minimum_followers_threshold}` }
  })
}
export const _getAllExpressPlaylistsDurations = (callback) => {
  API.get('express/curator/all-durations')
    .then(function ({ data }) {
      console.log(`durations ${data.durations}`)
      callback(data.durations)
    })
    .catch(function ({ data }) {
    })
}
export const _getAllExpressPlaylistsGenresWithQ = async (query = '') => {
  // const selectPopper = document.getElementsByClassName('select__menu')[0]
  // console.log(selectPopper.style = 'display: none;')
  // if (query === "" || query === null || query === undefined) {
  //   selectPopper.style = 'display: none;'
  // } else {
  //   selectPopper.style = 'display: block;'
  // }
  if (query.length < 3) {
    // If the user has not typed at least 3 letters, return an empty array
    //   const {data} =  await API.get('campaigns/genre/getAllGenresWithQ', {
    //     params: {
    //         limit: 100,
    //         query,
    //         page: 1
    //     }
    // })
    // return _.map(data.genres, (v, k) => {
    //     return {value: v.id, label: v.name}
    // })
    return
  }
  const { data } = await API.get('express/curator/playlist/genres', {
    params: {
      limit: 100,
      query,
      page: 1
    }
  })
  return _.map(data.data, (v, k) => {
    return { value: v.id, label: v.name }
  })
}
//

export const _getAllCustomCampaignsGenresWithQ = async (query = '') => {
  if (query.length < 3) {
    // If the user has not typed at least 3 letters, return an empty array /
    //   const {data} =  await API.get('campaigns/genre/getAllGenresWithQ', {
    //     params: {
    //         limit: 100,
    //         query,
    //         page: 1
    //     }
    // })
    // return _.map(data.genres, (v, k) => {
    //     return {value: v.id, label: v.name}
    // })
    return
  }
  const { data } = await API.get('express/curator/playlist/genres', {
    params: {
      limit: 100,
      query,
      page: 1
    }
  })
  return _.map(data.data, (v, k) => {
    return { value: v.id, label: v.name }
  })
}
/*************************** Curator Express Campaigns  ****************************/
export const _getExpressCuratorCampaigns = (data, callback, callbackErr) => {
  API.get(`express/curator/playlist/campaigns`, {
    params: data
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      // callbackErr(data?.data?.errors)
      const data = error?.response?.data
      if (data) {
        const { data: responseData } = data
        callbackErr(responseData?.errors)
      } else {
        console.log('Error:', error)
      }
    })
}

/*************************** Artist Express Campaigns  ****************************/

export const _getExpressArtistCampaigns = (data, callback, callbackErr) => {
  API.get(`express/artist/campaigns`, {
    params: data
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      // callbackErr(data?.data?.errors)
      const data = error?.response?.data
      if (data) {
        const { data: responseData } = data
        callbackErr(responseData?.errors)
      } else {
        console.log('Error:', error)
      }
    })
}

//get min tier price
export const _getExpressArtistMinTier = (callback, callbackErr) => {
  API.get(`express/artist/min/tier`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      // callbackErr(data?.data?.errors)
      const data = error?.response?.data
      if (data) {
        const { data: responseData } = data
        callbackErr(responseData?.errors)
      } else {
        console.log('Error:', error)
      }
    })
}

export const _checkUriUrl = (link, callback) => {
  const valid = 'https://open.spotify.com/track/'
  if (_.startsWith(link, valid)) {
    const track_id = link.substr(valid.length)
    API.get(`spotify/get-track`, {
      params: {
        track_id
      }
    })
      .then(({ data }) => callback({ code: 1, other: data?.track }))
  } else {
    callback({ code: 2 })
  }
}

export const _checkTrackIfIsExists = (data, callback, callbackErr) => {
  API.post(`express/track/exists`, {
    track_id: data.track_id,
    playlist_id: data.playlist_id
  })
    .then(({ data }) => callback({ data }))
}

export const _getExpressPlaylistArtistForApply = (data, callback, callbackErr) => {
  API.get(`express/artist/playlists`, {
    params: data
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      // callbackErr(data?.data?.errors)
      const data = error?.response?.data
      if (data) {
        const { data: responseData } = data
        callbackErr(responseData?.errors)
      } else {
        console.log('Error:', error)
      }
    })
}

export const _storeArtistExpressPlaylistCampaign = (data, callback, callbackErr) => {
  API_WithSwal.post(`express/artist/campaign/create`, data)
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}

//renew campaign
export const _renewArtistExpressPlaylistCampaign = (data, callback, callbackErr) => {
  API_WithSwal.post(`express/artist/campaign/renew`, data)
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}
//check if user renew campaign
export const _renewCheckArtistExpressPlaylistCampaign = (data, callback, callbackErr) => {
  API.post(`express/artist/campaign/renew/check`, data)
    .then(function (data) {
      callback(data)
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}

export const _stripePaymentCampaignSuccess = (data, callback, callbackErr) => {
  API.post(`stripe/campaign/checkout/check/pay`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}
/********* Tiktok Released Campaign ***********/
export const _checkTiktokMusic = (link, callback, callbackErr) => {
  const formData = new FormData()
  formData.append('sound_link', link)

  API.post('check-tiktok-music', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then((response) => {
      console.log(`response ${response}`)
      if (response) {
        callback({ code: 1, data: response })
      } else {
        callback({ code: 2 })
      }
    })
    .catch((error) => {
      console.error(error)
      callbackErr(error)
    })
}
/** TikTok Campaign Create */
export const _checkTiktokMusicExpress = (link, callback, callbackErr) => {
  const formData = new FormData()
  formData.append('sound_link', link)
  const valid = 'https://www.tiktok.com/music/'

  if (_.startsWith(link, valid)) {
    API.post('express-check-tiktok-music', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((response) => {
        console.log(`response ${response}`)
        if (response) {
          callback({ code: 1, data: response })
        } else {
          callback({ code: 2 })
        }
      })
      .catch((error) => {
        console.error(error)
        callbackErr(error)
      })
  } else {
    // Return code 2 if the link does not start with the valid string
    callback({ code: 2 })
  }
}
export const _checkTiktokVideo = (link, callback) => {
  const formData = new FormData()
  formData.append('video_link', link)

  API.post('check-tiktok-video', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then((response) => {
      console.log(`response ${response}`)
      if (response) {
        callback({ code: 1, data: response })
      } else {
        callback({ code: 2 })
      }
    })
    .catch((error) => {
      console.error(error)
      callback({ code: 2 })
    })
}
//***************** Languages *******************//

//************************************//
export const _getAllLanguagesWithQ = async (q = '') => {
  const { data } = await API.get('language/getAllLanguagesWithQ', {
    params: {
      limit: 20,
      q
    }
  })
  return _.map(data.languages, (v, k) => {
    return { value: v.id, label: v.name }
  })
}
//************************************//
export const _getAllTiers = (q = '', type, callback) => {
  API.get('tier/getAllTiersWithQ', {
    params: {
      limit: 100,
      q,
      type
    }
  }).then(({ data }) => {
    callback(data.tiers)
  })
}
//************************************//
export const _addTiktokPerformance = (data, callback, callbackErr) => {
  API.post(`campaign/tiktok/performance`, data)
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}
export const _addCampaign = (data, callback, callbackErr) => {
  const formData = new FormData()
  _.each(data, (x, key) => {
    if (key === 'media_link' && !_.isString(x)) {
      if (!_.isEmpty(x)) {
        formData.append(key, x[0])
      }
    } else {
      if (x) {
        formData.append(key, x)
      }
    }
  })

  Artist_API_WithSwal.post(`artist/campaigns`, formData, {
    headers: { "Content-Type": "multipart/form-data" }
  })
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}
//***************** Outgoing Payments & transfers *******************//
//get convert from usd to gbp
export const _getFromUsdToGbp = (amount, callback, callbackErr) => {
  API.get(`payments/wise/gbp/usd/${amount}`)
    .then(function ({ data }) {
      console.log(data)
      callback(data)
    })
    .catch(function (error) {
      // callbackErr(data?.data?.errors)
      const data = error?.response?.data
      if (data) {
        const { data: responseData } = data
        callbackErr(responseData?.errors)
      } else {
        console.log('Error:', error)
      }
    })
}
//payouts last 30 days
export const _getPayoutsLastMonthChartStats = (id, callback) => {
  API.get(`/curator/payouts/last/month/stats`)
    .then(function ({ data }) {
      callback(data.payouts)
    })
    .catch(function ({ data }) {
    })
}
//calculate curator sum
export const _getPayoutsSum = (callback, callbackErr) => {
  API.get(`curator/sum/payouts`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
       callbackErr(error.message)
      // const data = error?.response?.data
      // if (data) {
      //   const { data: responseData } = data
      //   callbackErr(responseData?.errors)
      // } else {
      //   console.log('Error:', error)
      // }
    })
}
//wise currencies search
export const _getAllWiseCurrenciesWithQ = async (q = '') => {
  const { data } = await API.get('payments/wise/currencies', {
    params: {
      limit: 30,
      q
    }
  })
  return _.map(data.currency, (v, k) => {
    return { value: v.id, label: v.currencyCode, target: v.isTargetCurrency }
  })
}
//outgoing payments with search
//wise currencies
export const _getAllOutGoingPaymentWithQ = async (q = '') => {
  const { data } = await API.get('payments/outgoing/types/search', {
    params: {
      limit: 30,
      q
    }
  })
  return _.map(data.payment, (v, k) => {
    return { value: v.id, label: v.name }
  })
}

//store wise payment info
export const _createPaymentInfo = (data, callback) => {
  API_WithSwal.post(`payments/wise/transfer/info/store`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}

//create quote and transfer request
// export const _createQuoteAndTransfer = async (id, callback) => {
//   //check user info if exists
//  await API.get(`payments/wise/user/info/check`)
//       .then(function ({data}) {
//           callback(data)
//       })
//       .catch(function ({data}) {
//       })
//   //check if user have unpaid payouts
//   await API.get(`payments/wise/unpaid/payouts/check`)
//       .then(function ({data}) {
//           callback(data)
//       })
//       .catch(function ({data}) {
//       })   

//      const account = await API.get(`payments/wise/create/account`)
//       .then(function ({data}) {
//           callback(data)
//       })
//       .catch(function ({data}) {
//       }) 
//       console.log(account.id)
// }

export const _createQuoteAndTransfer = async (data, callback) => {
  try {
    // Check user info if exists
    // const userInfo = await API.get(`payments/wise/user/info/check`)
    // Check if user has unpaid payouts
    const unpaidPayouts = await API.get(`payments/wise/unpaid/payouts/check`)
    // Get the sum
    const sum = await API.get(`curator/sum/payouts`)
    const sumValue = sum.data.sum

    if (Number(sum.data.sum) === 0) {
      _toast('No available balance', 'error')
      return
    }

    console.log(sum.data.sum)

    // Create the account
    // const account = await API.get(`payments/wise/create/account/${data.email}`)
    // const accountID = account.data.id

    // console.log(account.data.id)

    // Create the quote
    //const quote = await API.get(`payments/wise/create/quote/payouts/${data.gbp}`)
    const quote = await API.get(`payments/wise/create/quote/payouts`)
    const quoteID = quote.data.id

    console.log(quote.data.id)

    // Create transfer for payout and user
    const createTransfer = await API_WithSwal.post(`payments/wise/create/transfer`, {
      wise_account_id: '',
      wise_transfer_id: '',
      wise_balance_transaction_id: '',
      wise_quote_id: quoteID,
      amount: sumValue,
      status_id: 1,
      email: data.email,
      payment_id: data.payment_id
    })

    return {
      quote: quoteID,
      data: createTransfer.data
    }
  } catch (error) {
    throw error
  }
}

//view transfer details
//************************************//
export const _getTransferInfo = (id, callback) => {
  API.get(`payments/wise/user/transfer/view/${id}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}
//npm install file-saver to install file saver
export const _getDownloadPDFWise = (id, callback) => {
  API.get(`payments/wise/user/pdf/${id}`, { responseType: 'arraybuffer' })
    .then(function (response) {
      const filename = 'receipt.pdf'
      const contentType = 'application/pdf'

      const blob = new Blob([response], { type: contentType })
      console.log(blob)
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // For Internet Explorer
        window.navigator.msSaveBlob(blob, filename)
      } else {
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(url)
      }
      callback(response)
    })
    .catch(function (error) {
      console.error('Error occurred while downloading PDF', error)
    })
}
// export const _getDownloadPDFWise = (id, callback) => {
//   API.get(`payments/wise/user/pdf/${id}`)
//     .then(function (response) {
//       const blob = new Blob([response.data], { type: 'application/pdf' })
//       const url = URL.createObjectURL(blob)
//       const a = document.createElement('a')
//       a.href = url
//       a.download = 'receipt.pdf'
//       a.click()
//       callback(response.data)
//     })
//     .catch(function (error) {
//       console.error('Error occurred while downloading PDF', error)
//     })
// }

export const _deleteWiseUserInfo = (id, callback) => {
  API_WithSwal.delete(`payments/wise/user/info/delete/${id}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}

/***************************** Social OAuth ******************************/
//get gmail url
export const _getOauthGmailUrl = (callback) => {
  API.get(`oauth/gmail/redirect`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}
//get user gmail info and log or register
export const _getOauthGmailLog = (data, callback, callbackErr) => {
  API.get(`oauth/gmail/register/login?code=${data.code}`, {
    params: {
      auth_type: data.auth_type
    }
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (data) {
      callbackErr(data)
    })
}
//complete profile
export const _oauthCompleteRegister = (data, callback, callbackErr) => {
  API.post('oauth/gmail/register/complete', data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
      callbackErr(data.data)
    })
}

/*************** Code Verification *********************/


//complete profile
export const _sendVerificationCodeTransfer = (data, callback, callbackErr) => {
  API.post('payments/send/verification/code', data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {

    })
}
//verify email
export const _verifyCodeTransfer = (data, callback, callbackErr) => {
  API.post('payments/verify/code', data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}

//express campaign store review
export const _expressCampaignAddReview = (data, callback, callbackErr) => {
  API.post(`express/add/review`, data)
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}


/******************** spotify  connected accounts *********************/
export const _getCurrentCuratorConnectedAccounts = (callback, callbackErr) => {
  API.get(`connected/platforms/all/user/accounts`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}

/****************** Fan Finder  *****************/
export const _getFanFinderCheck = (callback, callbackErr) => {
  API.get(`fan-finder/check/artists`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}

export const _getCurrentArtistConnectedAccounts = (callback, callbackErr) => {
  API.get(`fan-finder/connection/artists`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}

export const _storeArtistAccount = (data, callback, callbackErr) => {
  API_WithSwal.post(`fan-finder/add/connection/artists`, data)
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}

export const _checkUriUrlProfile = (link, callback) => {
  const valid = 'https://open.spotify.com/artist/'
  const valid1 = 'https://artists.spotify.com/c/artist/'

  if (_.startsWith(link, valid)) {
    const id = link.substr(valid.length)

    API.get(`spotify/get/artist/info/${id}`).then(({ data }) => callback({ code: 1, other: data }))
  } else if (_.startsWith(link, valid1)) {
    const id = link.substr(valid1.length)

    // Remove any additional content after the artist ID
    const artistId = id.split('/')[0]

    API.get(`spotify/get/artist/info/${artistId}`).then(({ data }) => callback({ code: 1, other: data }))
  } else {
    callback({ code: 2 })
  }
}

export const _deleteArtistAccount = (id, callback) => {
  API_WithSwal.delete(`fan-finder/delete/connection/${id}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}

export const _getCurrentArtistFans = (callback, callbackErr) => {
  API.get(`fan-finder/artist/fans`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}
export const _getCurrentSimilarArtistFans = (callback, callbackErr) => {
  API.get(`fan-finder/artist/similar-fans`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}

//paypal url checkout
export const _paypalUrlCheckout = (data, callback, callbackErr) => {
  API.post(`payments/paypal/checkout/url`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}

export const _paypalCheckoutPaymentCapture = (data, callback, callbackErr) => {
  API.post(`payments/paypal/callback/url`, data)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}

//tiktok
export const _getLoginTiktokUrl = (callback) => {
  API.get(`express-tiktok/oauth/url`, {
    // params: {
    //   reconnect
    // }
  })
    .then(function (data) {
      callback(data.data)
    })
    .catch(function (data) {
    })
}

export const _getTiktokCallBack = (data, callback, callbackErr) => {
  API.post(`express-tiktok/tiktok/access/token`, {
    step: data?.step,
    code: data?.code,
    user: data?.user,
    tier_id: data?.tier_id,
    token: data?.token
  })
  // API.post(`express-tiktok/tiktok/access/token`, {
  //   params: {
  //     step: data?.step,
  //     code: data?.code,
  //     user: data?.user,
  //     tier_id: data?.tier_id,
  //     token: data?.token
      
  //   }
  // })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}

// export const _getMobileTiktokCallBack = (data, callback, callbackErr) => {
//   API.get(`mobile/tiktok/access/token?code=${data?.code}`, {
//     params: {
//       step: 0 
//     }
//   })
//     .then(function ({ data }) {
//       callback(data)
//     })
//     .catch(function ({ data }) {
//       callbackErr(data)
//     })
// }


export const _getCurrentInfluencerConnectedAccounts = (callback, callbackErr) => {
  API.get(`express-tiktok/tiktok/influencer/accounts`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}
/** Artist TikTok */

export const _getAllExpressTikTokDurations = (callback) => {
  API.get('express-tiktok/user/get-durations')
      .then(function ({ data }) {
          console.log(`durations ${data.durations}`)
          callback(data.durations)
      })
      .catch(function ({ data }) {
      })
}

export const _getAllExpressTikTokTiers = (callback) => {
  API.get('express-tiktok/user/get-tiers')
      .then(function ({ data }) {
          console.log(`tiers ${data.tiers}`)
          callback(data.tiers)
      })
      .catch(function ({ data }) {
      })
}

export const _getAllExpressTikTokUpdateTier = (data, callback) => {
  API.get('express-tiktok/tiktok/change/account/tier', {
    params: { tier_id: data.tier_id}
  })
      .then(function ({ data }) {
          callback(data)
      })
      .catch(function ({ data }) {
      })
}

export const _getAllExpressTikTokRevokeAccount = (callback) => {
  API.get('express-tiktok/tiktok/influencer/revoke')
      .then(function ({ data }) {
          callback(data)
      })
      .catch(function ({ data }) {
      })
}

/******* Artist Campaign ********/

export const _getExpressTikTokArtistCampaigns = (data, callback, callbackErr) => {
  API.get(`express-tiktok/artist/campaigns?page=1`, {
    params: data
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      // callbackErr(data?.data?.errors)
      const data = error?.response?.data
      if (data) {
        const { data: responseData } = data
        callbackErr(responseData?.errors)
      } else {
        console.log('Error:', error)
      }
    })
}

export const _getExpressTikTokCampaignInfo = (id, callback) => {
  API.get(`/express-tiktok/artist/show/campaign/${id}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}


//get campaign reasons
export const _getAllReasonsTikTokWithQ = async (q = '') => {
  const { data } = await API.get('express-tiktok/influencer/campaign/reasons?', {
    params: {
      limit: 20,
      q
    }
  })
  return _.map(data.reasons, (v, k) => {
    return { value: v.id, label: v.reason }
  })

}


//campaign decline
export const _declineExpressTikTokCampaign = (data, callback, callbackErr) => {
  API_WithSwal.post(`express-tiktok/influencer/decline/campaign`, data)
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}
export const _approveExpressTikTokCampaign = (data, callback, callbackErr) => {
  console.log(`approve campaign ${JSON.stringify(data)}`)
  API_WithSwal.post(`express-tiktok/influencer/approve/campaign`, data)
    .then(function () {
      callback()
    }).catch(function (data) {
      callbackErr(data?.data)
    })
}

export const _getExpressInfluencerCampaigns = (data, callback, callbackErr) => {
  API.get(`express-tiktok/influencer/campaigns`, {
    params: data
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      // callbackErr(data?.data?.errors)
      const data = error?.response?.data
      if (data) {
        const { data: responseData } = data
        callbackErr(responseData?.errors)
      } else {
        console.log('Error:', error)
      }
    })
}

export const _getExpressTikTokArtistForApply = (data, callback, callbackErr) => {
  API.get(`express-tiktok/artist/accounts/campaigns`, {
    params: data
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      // callbackErr(data?.data?.errors)
      const data = error?.response?.data
      if (data) {
        const { data: responseData } = data
        callbackErr(responseData?.errors)
      } else {
        console.log('Error:', error)
      }
    })
}


export const _checkTikTokTrackIfIsExists = (data, callback, callbackErr) => {
  API.post(`express-tiktok/track/exists`, {
    track_id: data.track_id,
    influencer_oauth_id: data.influencer_oauth_id
  })
    .then(({ data }) => callback({ data }))
}


export const _storeArtistExpressTikTokCampaign = (data, callback, callbackErr) => {
  API_WithSwal.post(`express-tiktok/artist/campaign/create`, data)
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}


export const _getExpressTikTokInfluencerVideos = (data, callback, callbackErr) => {
  API.get(`express-tiktok/tiktok/list/videos?`, {
    params: data
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      
    })
}

//express campaign store review
export const _expressTikTokCampaignAddReview = (data, callback, callbackErr) => {
  API.post(`express-tiktok/influencer/add/review`, data)
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}

//check if user renew campaign
export const _renewCheckArtistTikTokExpressCampaign = (data, callback, callbackErr) => {
  API.post(`express-tiktok/artist/campaign/renew/check`, data)
    .then(function (data) {
      callback(data)
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}

//renew campaign
export const _renewArtistExpressTikTokCampaign = (data, callback, callbackErr) => {
  API_WithSwal.post(`express-tiktok/artist/campaign/renew`, data)
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}

/**Wallet */

export const _walletGetAllPackagesWithQForFilter = (q = '', convert = 0, currency, callback) => {
  API.get('wallet/user/get/all-packages', {
    params: {
      limit: 100,
      convert,
      currency,
      q
    }
  }).then(({ data }) => {
    callback(data.packages)
  })
}

export const _rechargeWalletMethod = (data, callback, callbackErr) => {
  API_WithSwal.post(`wallet/user/recharge/coins`, data)
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}
//available balance
export const _walletAxiosGetAllUserAvailableBalance = (token, q = '', convert = 0, callback, callbackErr) => {
  axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}wallet/user/current/balance`, {
    params: {
      currency: q,
      convert
    },
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
    .then(function ({data}) {
      callback(data)
    })
    .catch(function (error) {
      const data = error?.response?.data
      if (data) {
        const { data: responseData } = data
        callbackErr(responseData?.errors)
      } else {
        console.log('Error:', error)
      }
    })
}

export const _walletGetAllUserAvailableBalance = (q = '', convert = 0, callback, callbackErr) => {
  API.get(`wallet/user/current/balance`, {
    params: {
      currency : q,
      convert 
    }
  })
    .then(function ({data}) {
      callback(data)
    })
    .catch(function (error) {
      // callbackErr(data?.data?.errors)
      const data = error?.response?.data
      if (data) {
        const { data: responseData } = data
        callbackErr(responseData?.errors)
      } else {
        console.log('Error:', error)
      }
    })
}

export const _wiseGetAllCurrencies = (q = '', callback) => {
  API.get('payments/wise/currency-get', {
    params: {
      limit: 100,
      q
    }
  }).then(({ data }) => {
    callback(data)
  })
}
/********** Custom Campaign pay coins ***************/
export const _payCoinsCustomCampaign = (id, callback) => {
  API_WithSwal.get(`pay/coins/custom/campaign/${id}`)
  .then(({ data }) => {
    callback(data)
  })
}

export const _checkPlaylistIfIsValid = (link, callback, callbackErr) => {
  const valid = 'https://open.spotify.com/playlist/'
  console.log("Received link:", link)
  if (_.startsWith(link, valid)) {
      API.post(`express/user/playlist/info`, {
          spotify_url: link
      })
          .then(({ data }) => callback({ code: 1, ...data }))
  } else {
      callback({ code: 2 })
  }
}

export const _checkPlaylistIfIsExists = (id, callback, callbackErr) => {
  API.post(`express/user/playlist/exists`, {
      playlist: id
  })
      .then(({ data }) => callback({ data }))
}

export const _checkPlaylistIfTrackIsValid = (link, callback) => {

  if (_.startsWith(link, 'https://open.spotify.com/playlist/')) {
      const playlist_id = link.split('/')[link.split('/').length - 1]
      SPOTIFY_API.get(`get-playlist`, {
          params: {
              playlist_id
          }
      })
          .then(({ data }) => callback({ code: 1, ...data?.playlist }))
  } else {
      callback({ code: 2 })
  }
}

//check playlist of curator
export const _curatorCheckSpotifyAccountVerify = async (data, callback, callbackErr) => {
  API.post(`express/verify/check/account/playlist`, data)
    .then(function (data) {
      callback(data)
    })
    .catch(function (data) {
      
    })
}
//dashbaord campaigns
export const _getUserDashCampaigns = (callback, callbackErr) => {
  API.get(`web/user/campaigns/dashboard`)
    .then(function ({ data }) {
      callback(data.campaigns)
    })
    .catch(function (error) {
      // callbackErr(data?.data?.errors)
      const data = error?.response?.data
      if (data) {
        const { data: responseData } = data
        callbackErr(responseData?.errors)
      } else {
        console.log('Error:', error)
      }
    })
}
/** SLiders */
export const _getUserSliders = (callback, callbackErr) => {
  API.get(`user/slider`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      // callbackErr(data?.data?.errors)
      const data = error?.response?.data
      if (data) {
        const { data: responseData } = data
        callbackErr(responseData?.errors)
      } else {
        console.log('Error:', error)
      }
    })
}

//instagram
export const _getLoginInstagramUrl = (callback) => {
  API.get(`express-instagram/oauth/url`, {
    // params: {
    //   reconnect
    // }
  })
    .then(function (data) {
      callback(data.data)
    })
    .catch(function (data) {
    })
}

//callback
export const _getInstagramCallBack = (data, callback, callbackErr) => {
  API.post(`express-instagram/instagram/access/token`, {
    step: data?.step,
    code: data?.code,
    user: data?.user,
    tier_id: data?.tier_id,
    token: data?.token
  })
  // API.get(`express-instagram/instagram/access/token?code=${data?.code}`, {
  //   params: {
  //     step: data?.step,
  //     user: data?.user,
  //     tier_id: data?.tier_id,
  //     // is_renewable: data?.is_renewable,
  //     token: data?.token
      
  //   }
  // })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}


export const _getAllExpressInstagramTiers = (callback) => {
  API.get('express-instagram/user/get-tiers')
      .then(function ({ data }) {
          console.log(`tiers ${data.tiers}`)
          callback(data.tiers)
      })
      .catch(function ({ data }) {
      })
}

export const _getAllExpressInstagramUpdateTier = (data, callback) => {
  API.get('express-instagram/instagram/change/account/tier', {
    params: { tier_id: data.tier_id}
  })
      .then(function ({ data }) {
          callback(data)
      })
      .catch(function ({ data }) {
      })
}
/**Instagram Campaign Create */
export const _checkInstagramMusicExpress = (link, callback, callbackErr) => {
  const formData = new FormData()
  formData.append('sound_link', link)
  const valid = 'https://www.instagram.com/reels/audio/'
  
  if (_.startsWith(link, valid)) {
    API.post('express-instagram/check-instagram-music', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((response) => {
        console.log(`response ${response}`)
        if (response) {
          callback({ code: 1, data: response.data })
        } else {
          callback({ code: 2 })
        }
      })
      .catch((error) => {
        console.error(error)
        callbackErr(error)
      })
  } else {
    // Return code 2 if the link does not start with the valid string
    callback({ code: 2 })
  }
}

export const _getExpressInstagramArtistForApply = (data, callback, callbackErr) => {
  API.get(`express-instagram/artist/accounts/campaigns`, {
    params: data
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      // callbackErr(data?.data?.errors)
      const data = error?.response?.data
      if (data) {
        const { data: responseData } = data
        callbackErr(responseData?.errors)
      } else {
        console.log('Error:', error)
      }
    })
}
//create instagram campaign
export const _storeArtistExpressInstagramCampaign = (data, callback, callbackErr) => {
  API_WithSwal.post(`express-instagram/artist/campaign/create`, data)
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}


export const _getExpressInstagramArtistCampaigns = (data, callback, callbackErr) => {
  API.get(`express-instagram/artist/campaigns?page=1`, {
    params: data
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      // callbackErr(data?.data?.errors)
      const data = error?.response?.data
      if (data) {
        const { data: responseData } = data
        callbackErr(responseData?.errors)
      } else {
        console.log('Error:', error)
      }
    })
}

export const _getExpressInstagramCampaignInfo = (id, callback) => {
  API.get(`express-instagram/artist/show/campaign/${id}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}
export const _getExpressInstagramInfluencerCampaigns = (data, callback, callbackErr) => {
  API.get(`express-instagram/influencer/campaigns`, {
    params: data
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      // callbackErr(data?.data?.errors)
      const data = error?.response?.data
      if (data) {
        const { data: responseData } = data
        callbackErr(responseData?.errors)
      } else {
        console.log('Error:', error)
      }
    })
}

//get campaign reasons
export const _getAllReasonsInstagramWithQ = async (q = '') => {
  const { data } = await API.get('express-instagram/influencer/campaign/reasons?', {
    params: {
      limit: 20,
      q
    }
  })
  return _.map(data.reasons, (v, k) => {
    return { value: v.id, label: v.reason }
  })

}

//campaign decline
export const _declineExpressInstagramCampaign = (data, callback, callbackErr) => {
  API_WithSwal.post(`express-instagram/influencer/decline/campaign`, data)
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}

export const _getExpressInstagramInfluencerVideos = (data, callback, callbackErr) => {
  API.get(`express-instagram/influencer/instagram/reels?`, {
    params: data
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      
    })
}

export const _approveExpressInstagramCampaign = (data, callback, callbackErr) => {
  console.log(`approve campaign ${JSON.stringify(data)}`)
  API_WithSwal.post(`express-instagram/influencer/approve/campaign`, data)
    .then(function () {
      callback()
    }).catch(function (data) {
      callbackErr(data?.data)
    })
}

//express campaign store review
export const _expressInstagramCampaignAddReview = (data, callback, callbackErr) => {
  API.post(`express-instagram/influencer/add/review`, data)
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}
export const _deleteInstagramRevokeAccount = (callback, callbackErr) => {
  API_WithSwal.get(`express-instagram/instagram/revoke/account`)
    .then(function () {
      callback()
    }).catch(function (data) {
      callbackErr(data?.data)
    })
}

export const _checkInstagramVideo = (link, callback) => {
  const formData = new FormData()
  formData.append('link', link)

  API.post('express-instagram/influencer/check/music', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then((response) => {
      console.log(`response ${response}`)
      if (response) {
        callback({ code: 1, data: response })
      } else {
        callback({ code: 2 })
      }
    })
    .catch((error) => {
      console.error(error)
      callback({ code: 2 })
    })
}

export const _instagramCheckAccountAccount = (callback, callbackErr) => {
  API.get(`express-instagram/influencer/check/account`)
    .then(function () {
      callback()
    }).catch(function (data) {
      callbackErr(data?.data)
    })
}


export const _checkAccountInfluencerCampaigns = (data, callback, callbackErr) => {
  API.get(`express-instagram/influencer/account/check`, {
    params: data
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}

export const _checkAccountInfluencerTikTokCampaigns = (callback, callbackErr) => {
  API.get(`express-tiktok/tiktok/influencer/check`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
    })
}

/******  *********/
export const _connectedAccountsSearchForArtist = async (artistName, callback) => {
  if (!artistName || artistName.length < 3) {
    return [] // Return an empty array if the artistName is null or less than 3 characters
  }
  

  const { data } = await API.get('spotify/search-artists', {
    params: {
      artist_name: artistName
    }
  })
  return _.map(data, (v) => {
    return { ...v, value: v.id, label: v.name }
  })
}

export const _connectedPlatformAddAccount = (data, callback, callbackErr) => {
  API.post(`connected/platforms/artist/connect/spotify/account`, data)
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data)
    })
}

export const _getArtistConnectedAccounts = (callback, callbackErr) => {
  API.get(`connected/platforms/artist/connected/accounts`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}

export const _getArtistSpotifyTracks = (data, callback, callbackErr) => {
  API.get(`connected/platforms/artist/connected/tracks`, {
    params: data
  })
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      if (typeof callbackErr === 'function') {
        callbackErr(error)
      } else {
        console.error('Error:', error)
      }
    })
}
//check if artist is connected to spotify
export const _checkArtistSpotifyAccountIfConnected = (callback, callbackErr) => {
  API.get(`connected/platforms/artist/check/if/connected`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}